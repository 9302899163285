import { useEffect, useState } from "react";
import AudioContainer from "./Components/Audio/AudioContainer";
import Transcript from "./Components/Transcript/Transcript";
import sampleAudio from "./sampleAudio/customer_support.wav";
import sampleAudio1 from "./sampleAudio/e-commerce-call.wav";
import "./App.scss";
import { Link } from "react-router-dom";

const samples = [
  {
    name: "example_1",
    src: sampleAudio,
    displayName: "customer_support",
    src_api: "../../sampleAudio/customer_support.wav",
    speaker: 2,
  },
  {
    name: "example_2",
    displayName: "e-commerce-call",
    src: sampleAudio1,
    src_api: "../../sampleAudio/e-commerce-call.wav",
    speaker: 2,
  },
];

const Application = () => {
  const [selectedSample, setSelectedSample] = useState({});
  const [selectedSamples, setSelectedSamples] = useState([]);

  const [isMobileScreen, setIsMobileScreen] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileScreen(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  // console.log("asda", selectedSamples);

  // useEffect(() => {

  //   const clearLocalStorage = () => {
  //     localStorage.clear();
  //   };

  //   window.addEventListener("beforeunload", clearLocalStorage);

  //   return () => {
  //     window.removeEventListener("beforeunload", clearLocalStorage);
  //   };
  // }, []);

  const handleCheckboxChange = (sample) => {
    if (selectedSamples.length === 1 && selectedSamples[0].src === sample.src) {
      setSelectedSamples([]);
    } else {
      setSelectedSamples([sample]);
    }
  };

  const handleLoadClick = () => {
    console.log(selectedSamples)
    if (selectedSamples.length === 1) {
      setSelectedSample(selectedSamples[0]);
    } else {
      console.warn("Please select exactly one sample to load.");
    }
  };

  const buttonStyle = {
    padding: "0.5rem 1rem",
    fontWeight: "500",
    fontSize: "1rem",
    color: "white",
    backgroundColor: "#336c96",
    border: "none",
    borderRadius: "5px",
    width: "6rem",
    cursor: "pointer"
  };

  return (
    <>
      <div className="appContainer" style={{ background: "white" }}>
        <div
          className=""
          style={{ paddingTop: "1rem", margin: "0 6rem 0 6rem" }}
        >
          <Link to="/">
            <img
              src={require("./Components/images/blueticklogo.png")}
              className="appContainer-header-logoImage"
              alt="logo"
            />
          </Link>
        </div>

        <div
          className="container"
          style={{ paddingTop: "4rem", display: "flex" }}
        >
          <div className="container-audioContainer">
            <div className="container-audioContainer-text">
              <span className="container-audioContainer-text-heading">
                {" "}
                Sales Call Evaluator
              </span>
              <span className="container-audioContainer-text-subheading">
                Upload your own audio file or select from the example table
              </span>
              <span className="container-audioContainer-text-info">
                {" "}
                Upload Audio File Here
              </span>
            </div>

            <AudioContainer
              data={selectedSample}
              clearData={() => setSelectedSample({})}
            />
          </div>

          <div
            className="container-sampleContainer"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span
              style={{
                fontSize: "1.5rem",
                fontWeight: "600",
                color: "#000",
                marginBottom: "1rem",
              }}
            >
              Load Example Audio
            </span>
            <table
              style={{
                width: "auto",
                borderCollapse: "collapse",
                marginTop: "20px", // Increase the top margin
                fontSize: "18px", // Further increase the font size
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      border: "1px solid #ddd",
                      padding: "0 25px", // Further increase the padding
                      backgroundColor: "#f2f2f2",
                      textAlign: "start",
                      width: "auto",
                    }}
                  >
                    Select
                  </th>
                  <th
                    style={{
                      border: "1px solid #ddd",
                      padding: "15px", // Further increase the padding
                      backgroundColor: "#f2f2f2",
                      textAlign: "start",
                    }}
                  >
                    Example audio
                  </th>
                  <th
                    style={{
                      border: "1px solid #ddd",
                      padding: "15px", // Further increase the padding
                      backgroundColor: "#f2f2f2",
                      textAlign: "start",
                    }}
                  >
                    No. of Speakers
                  </th>
                </tr>
              </thead>
              <tbody>
                {samples.map((e, idx) => (
                  <tr key={idx}>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      <input
                        type="checkbox"
                        checked={
                          selectedSamples.length === 1 &&
                          selectedSamples[0].src === e.src
                        }
                        onChange={() => handleCheckboxChange(e)}
                      />
                    </td>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px", // Further increase the padding
                        cursor: "pointer",
                      }}
                      // onClick={() => setSelectedSample(e)}
                    >
                      {e.displayName}
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                      {e.speaker}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div style={{ marginTop: "1rem" }}>
              <button style={buttonStyle} onClick={handleLoadClick}>
                Load
              </button>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "end",
          }}
        >
          <div
            style={{
              margin: "0 3rem",
              paddingLeft: "4rem",
              paddingRight: "4rem",
              marginTop: "4rem",
            }}
          >
            <hr />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              paddingTop: "2rem",
              paddingBottom: "0rem",
              borderTop: "1px solid rgba(255, 255, 255, 0.2)",
              "@media (min-width: 640px)": {
                // This is not supported in inline styles
                flexDirection: "row",
              },
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "0 3rem",
                paddingLeft: "4rem",
                paddingRight: "4rem",
              }}
            >
              <p style={{ fontSize: "small", color: "#000" }}>
                2023 © All rights reserved by Bluetick Consultants LLP
              </p>
              <div
                style={{
                  display: isMobileScreen ? "" : "flex",
                  alignItems: "",
                  gap: "1rem",
                }}
              >
                <a href="https://www.instagram.com/bluetickconsultants/">
                  <img
                    width="30"
                    height="30"
                    src="https://img.icons8.com/ios-filled/50/instagram-new--v1.png"
                    alt="instagram-new--v1"
                  />
                </a>

                <a href="https://twitter.com/BluetickConsult">
                  <img
                    width="30"
                    height="30"
                    src="https://img.icons8.com/ios-glyphs/30/twitter--v1.png"
                    alt="twitter--v1"
                  />
                </a>

                <a href="https://www.facebook.com/bluetickconsultants/">
                  <img
                    width="30"
                    height="30"
                    src="https://img.icons8.com/ios-filled/50/facebook-new.png"
                    alt="facebook-new"
                  />
                </a>

                <a href="https://www.linkedin.com/company/bluetick-consultants/">
                  <img
                    width="30"
                    height="30"
                    src="https://img.icons8.com/ios-filled/50/linkedin.png"
                    alt="linkedin"
                  />
                </a>
                <a href="https://www.threads.net/@bluetickconsultants">
                  <img
                    width="30"
                    height="30"
                    src="https://img.icons8.com/pulsar-line/48/threads.png"
                    alt="threads"
                  />
                </a>
                <a href="https://www.quora.com/profile/Bluetick-Consultants">
                  <img
                    width="30"
                    height="30"
                    src="https://img.icons8.com/windows/32/quora.png"
                    alt="quora"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Application;
