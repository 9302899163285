import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./benifts.scss";
import Feature from "./Components/Feature.js";
import Benifts from "./Components/Benifts.js";
import Image from "./images/landing.png";

const Home = () => {
  const datatwo = [
    {
      slist: ["#ebac79", "#d65b56"],
      title: "Comprehensive Call Understanding",
      ptext:
        "Gain a Comprehensive Understanding of Your Sales Calls with In-Depth Insights, Including Sentiment Analysis and Speech Clarity Checks.",
    },
    {
      slist: ["#90cbb7", "#2fb1a9"],
      title: "Time-Saving Call Summaries",
      ptext:
        "Save Time with Concise Call Overviews and Summaries, Providing Quick and Actionable Insights.",
    },
    {
      slist: ["#8a7876", "#32201c"],
      title: "Efficient Reference and Analysis",
      ptext:
        "Streamline Reference and Analysis Tasks with Automatically Generated Detailed Transcripts for Quick Access.",
    },
    {
      slist: ["#a6c869", "#37a65a"],
      title: "Enhanced Data Security",
      ptext:
        "Ensure the Protection of Your Sales Call Data with Server-Based Security Measures.",
    },
  ].reverse();

  const featureRef = useRef(null);
  const benefitsRef = useRef(null);

  const handleFeatureClick = () => {
    featureRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleBenefitsClick = () => {
    benefitsRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const [isOpen, setIsOpen] = useState(false);

  const [isMobileScreen, setIsMobileScreen] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileScreen(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const navStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: isMobileScreen ? "" : "0 3rem",
    paddingLeft: isMobileScreen ? "" : "4rem",
    paddingRight: isMobileScreen ? "" : "4rem",
  };

  const navItemStyle = {
    fontSize: "1.4rem",
    display: isMobileScreen ? "none" : "", //
    cursor: "pointer",
    fontWeight: "normal",
  };

  const buttonStyle = {
    padding: "0.5rem 1rem", // Adjusted for px-4 py-1
    fontWeight: "500",
    fontSize: "1rem",
    color: "white",
    backgroundColor: "#046ac9",
    border: "none",
    borderRadius: "5px",
    width: "6rem",
  };

  // Styles for the outermost div
  const outerDivStyle = {
    display: "flex",
    flexDirection: isMobileScreen ? "column" : "row", // Change flex direction based on screen width
    gap: isMobileScreen ? "8rem" : "15rem", // Adjust gap size
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0 1rem",
    marginLeft: "2rem",
    marginRight: "2rem",
    paddingLeft: "4rem",
    paddingRight: "4rem",
  };

  // Styles for the inner div
  const innerDivStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    width: isMobileScreen ? "100%" : "25rem", // Adjust width based on screen width
  };

  // Styles for the image
  const imageStyle = {
    width: isMobileScreen ? "100%" : "100%", // Adjust image width
  };

  // Button style (assuming you have defined this somewhere)

  return (
    <div style={{ background: "#fff", width: "100%", paddingRight: "2rem" }}>
      <div>
        <div style={navStyle}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="https://media.licdn.com/dms/image/C510BAQEzP4y4oeD4IA/company-logo_200_200/0/1631369563060?e=1707955200&v=beta&t=eRPP7vUWz6dSrImFSLR7JH7LetvvyacUgnP9OlHOeHU"
              alt="Logo"
              style={{ width: "10rem" }}
            />
          </div>
          <div style={{ display: "flex", gap: "2rem", alignItems: "center" }}>
            <h1 style={navItemStyle} onClick={handleFeatureClick}>
              Feature
            </h1>
            <h1 style={navItemStyle} onClick={handleBenefitsClick}>
              Benefits
            </h1>
            <Link to="/app">
              <button style={buttonStyle}>Go to App</button>
            </Link>
          </div>
        </div>

        {/* middle */}
        <div style={outerDivStyle}>
          <div style={innerDivStyle}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.9rem",
              }}
            >
              <h1
                style={{
                  fontSize: "2.5rem",
                  fontWeight: "bold",
                  color: "#000",
                }}
              >
                Sales Call Evaluator
              </h1>
              <h1
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  color: "#000",
                  width: isMobileScreen ? "" : "35rem",
                }}
              >
                Optimize Your Sales Performance with Sales Call Evaluator
              </h1>
            </div>
            <h1
              style={{
                fontSize: "1rem",
                fontWeight: "bold",
                color: "#000",
                width: isMobileScreen ? "" : "30rem",
                fontWeight: "normal",
                marginTop: "0.9rem",
              }}
            >
              Empower your sales teams with the cutting-edge Sales Call Evaluator. Say goodbye to time-consuming call documentation. Our revolutionary tool generates audio call transcripts, simplifying the evaluation process and saving valuable time.
            </h1>
            <Link to="/app">
              <button style={buttonStyle}>Try Now</button>
            </Link>
          </div>
          <div style={{ width: "100%" }}>
            <img
              src={Image}
              style={imageStyle}
              alt="Privacy Designer"
            />
          </div>
        </div>

        {/* <Feature /> */}
        <div style={{ marginTop: "8rem" }} ref={featureRef}>
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "3rem",
              marginBottom: "1rem",
              paddingTop: "3rem",
            }}
          >
            Feature
          </p>
          <Feature />
        </div>
        <div style={{}} ref={benefitsRef}>
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "3rem",
              marginBottom: "1rem",
              paddingTop: "3rem",
            }}
          >
            Benefits
          </p>
          <Benifts />
        </div>
        {/* footer */}
        <div
          style={{
            position: "",
            // marginTop: "16rem",
            backgroundColor: "",
          }}
        >
          <div style={{ padding: "3rem" }}>
            <div
              style={{
                display: "grid",
                gap: "10rem",
                marginBottom: "2rem",
              }}
            >
              <div style={{ maxWidth: "none" }}>
                {/* <img
                  src="https://www.bluetickconsultants.com/images/b-logo.svg"
                  style={{ width: "8rem" }}
                  alt="Logo"
                /> */}
                {/* Additional content */}
              </div>
              {/* Additional columns */}
            </div>

            <div
              style={{
                margin: "0 6.8rem",
                marginBottom: "9rem",
                paddingLeft: "4rem",
                paddingRight: "4rem",
                display: isMobileScreen ? "" : "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingTop: "4rem",
                paddingBottom: "4rem",
                background: "linear-gradient(to right, #f5f5f5, #ffffff)",
                borderRadius: "10px",
              }}
            >
              <h1
                style={{
                  fontSize: "2rem",
                  fontWeight: "bold",
                  color: "#000",
                }}
              >
                Experience the power of Sales Call Evaluator
              </h1>

              <Link to="/app">
                <button style={buttonStyle}>Go to App</button>
              </Link>
            </div>
            <div
              style={{
                margin: "0 3rem",
                paddingLeft: "4rem",
                paddingRight: "4rem",
              }}
            >
              <hr />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                paddingTop: "2rem",
                paddingBottom: "0rem",
                borderTop: "1px solid rgba(255, 255, 255, 0.2)",
                "@media (min-width: 640px)": {
                  // This is not supported in inline styles
                  flexDirection: "row",
                },
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: "0 3rem",
                  paddingLeft: "4rem",
                  paddingRight: "4rem",
                }}
              >
                <p style={{ fontSize: "small", color: "#000" }}>
                  2023 © All rights reserved by Bluetick Consultants LLP
                </p>
                <div
                  style={{
                    display: isMobileScreen ? "" : "flex",
                    alignItems: "",
                    gap: "1rem",
                  }}
                >
                  <a href="https://www.instagram.com/bluetickconsultants/">
                    <img
                      width="30"
                      height="30"
                      src="https://img.icons8.com/ios-filled/50/instagram-new--v1.png"
                      alt="instagram-new--v1"
                    />
                  </a>

                  <a href="https://twitter.com/BluetickConsult">
                    <img
                      width="30"
                      height="30"
                      src="https://img.icons8.com/ios-glyphs/30/twitter--v1.png"
                      alt="twitter--v1"
                    />
                  </a>

                  <a href="https://www.facebook.com/bluetickconsultants/">
                    <img
                      width="30"
                      height="30"
                      src="https://img.icons8.com/ios-filled/50/facebook-new.png"
                      alt="facebook-new"
                    />
                  </a>

                  <a href="https://www.linkedin.com/company/bluetick-consultants/">
                    <img
                      width="30"
                      height="30"
                      src="https://img.icons8.com/ios-filled/50/linkedin.png"
                      alt="linkedin"
                    />
                  </a>
                  <a href="https://www.threads.net/@bluetickconsultants">
                    <img
                      width="30"
                      height="30"
                      src="https://img.icons8.com/pulsar-line/48/threads.png"
                      alt="threads"
                    />
                  </a>
                  <a href="https://www.quora.com/profile/Bluetick-Consultants">
                    <img
                      width="30"
                      height="30"
                      src="https://img.icons8.com/windows/32/quora.png"
                      alt="quora"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
