import React, { useState, useEffect } from "react";

import "./App.scss";
import Transcript from "./Components/Transcript/Transcript";
import { Link } from "react-router-dom";
import "./Components/Transcript/transcript.css";
import Button from "@mui/material/Button";
import TableContent from "./Components/MainPage/TableContent";

const MainPage = () => {
  const storedAnalyzeData = localStorage.getItem("analyzeData");
  const analyzeData = storedAnalyzeData ? JSON.parse(storedAnalyzeData) : null;
  const storedGenerateData = localStorage.getItem("generateData");
  const [isMobileScreen, setIsMobileScreen] = useState(window.innerWidth < 768);

  const generateData = storedGenerateData
    ? JSON.parse(storedGenerateData)
    : null;

  console.log("data", analyzeData, "data2", generateData);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileScreen(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div style={{ margin: "0 4rem 0 4rem" }}>
      <div className="" style={{ paddingTop: "1rem" }}>
        <Link to="/">
          <img
            src={require("./Components/images/blueticklogo.png")}
            className="appContainer-header-logoImage"
            alt="logo"
          />
        </Link>
      </div>
      {/* <div className="" style={{ paddingTop: "1rem" }}>
        <Link to="/">
          <img
            src={require("./Components/images/blueticklogo.png")}
            className="appContainer-header-logoImage"
            alt="logo"
          />
        </Link>
      </div>
      <div className="summaryContainer" style={{ paddingTop: "3rem" }} >
        <div className="summaryContainer-transcriptContainer">
          <Transcript />
        </div>
      </div> */}

      <div style={{ marginTop: "2rem" }}>
        <span
          style={{
            fontSize: "2.2rem",
            fontWeight: "500",
            color: "#000",
          }}
        >
          Dashboard
        </span>
      </div>

      <div style={{ marginTop: "2rem" }}>
        <span
          style={{
            fontSize: "1.5rem",
            marginBottom: "4rem",
            // fontWeight: "500",
          }}
        >
          Call Summary
        </span>

        <div
          style={{
            display: `${isMobileScreen ? `` : "flex"}`,
            gap: "1rem",
            marginTop: "2rem",
          }}
        >
          <div style={{ width: `${isMobileScreen ? `` : "50%"}` }}>
            <div>
              <div
                className=""
                style={{
                  fontSize: "1.2rem",
                  fontWeight: "500",
                  color: "#000",
                }}
              >
                Transcript
              </div>
            </div>
            <div className="ts-container">
              <div
                style={{
                  padding: "1rem",
                  height: "15rem",
                  overflow: "auto",
                  paddingBottom: "2rem",
                }}
              >
                <div
                  className=""
                  style={{
                    fontSize: "1rem",
                    fontWeight: "semiBold",
                    whiteSpace: 'pre-line',
                    fontFamily: "inherit",
                    color: "#000",
                  }}
                >
                  {analyzeData?.transcript &&
                    analyzeData.transcript
                      .split(/SPEAKER 1|SPEAKER 2|SPEAKER/)
                      .join("\nSPEAKER")}
                </div>
              </div>
            </div>
          </div>

          <div style={{ width: `${isMobileScreen ? `` : "50%"}` }}>
            <div
              className=""
              style={{
                fontSize: "1.2rem",
                fontWeight: "500",
                color: "#000",
              }}
            >
              Summary
            </div>
            <div className="ts-container">
              <div
                style={{
                  padding: "1rem",
                  height: "15rem",
                  overflow: "auto",
                  whiteSpace: 'pre-line',
                  paddingBottom: "2rem",
                }}
              >
                <div
                  className=""
                  style={{
                    fontSize: "1rem",
                    fontFamily: "inherit",
                    fontWeight: "semiBold",
                    color: "#000",
                    marginBottom: "2rem",
                  }}
                >
                  {generateData?.data?.summary}
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr style={{ marginTop: "2rem" }} />

        <div style={{ marginTop: "2rem" }}>
          <span
            style={{
              fontSize: "1.5rem",
              // fontWeight: "600",
              color: "#000",
              marginBottom: "1rem",
            }}
          >
            Analysis
          </span>
          <TableContent analyzeData={analyzeData} generateData={generateData} />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "end",
        }}
      >
        <div
          style={{
            // margin: "0 3rem",
            // paddingLeft: "4rem",
            // paddingRight: "4rem",
            // marginTop: "4rem",
            paddingTop: "2.5rem",
          }}
        >
          <hr />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            paddingTop: "2rem",
            paddingBottom: "0rem",
            borderTop: "1px solid rgba(255, 255, 255, 0.2)",
            "@media (min-width: 640px)": {
              // This is not supported in inline styles
              flexDirection: "row",
            },
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "0 3rem",
              paddingLeft: "4rem",
              paddingRight: "4rem",
            }}
          >
            <p style={{ fontSize: "small", color: "#000" }}>
              2023 © All rights reserved by Bluetick Consultants LLP
            </p>
            <div
              style={{
                display: isMobileScreen ? "" : "flex",
                alignItems: "",
                gap: "1rem",
              }}
            >
              <a href="https://www.instagram.com/bluetickconsultants/">
                <img
                  width="30"
                  height="30"
                  src="https://img.icons8.com/ios-filled/50/instagram-new--v1.png"
                  alt="instagram-new--v1"
                />
              </a>

              <a href="https://twitter.com/BluetickConsult">
                <img
                  width="30"
                  height="30"
                  src="https://img.icons8.com/ios-glyphs/30/twitter--v1.png"
                  alt="twitter--v1"
                />
              </a>

              <a href="https://www.facebook.com/bluetickconsultants/">
                <img
                  width="30"
                  height="30"
                  src="https://img.icons8.com/ios-filled/50/facebook-new.png"
                  alt="facebook-new"
                />
              </a>

              <a href="https://www.linkedin.com/company/bluetick-consultants/">
                <img
                  width="30"
                  height="30"
                  src="https://img.icons8.com/ios-filled/50/linkedin.png"
                  alt="linkedin"
                />
              </a>
              <a href="https://www.threads.net/@bluetickconsultants">
                <img
                  width="30"
                  height="30"
                  src="https://img.icons8.com/pulsar-line/48/threads.png"
                  alt="threads"
                />
              </a>
              <a href="https://www.quora.com/profile/Bluetick-Consultants">
                <img
                  width="30"
                  height="30"
                  src="https://img.icons8.com/windows/32/quora.png"
                  alt="quora"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
