import React, { useState, useEffect, useRef, Fragment } from "react";
import WaveSurfer from "wavesurfer.js";

import { FaPlay, FaUndo, FaRedo, FaTrash } from "react-icons/fa";
import { FaPause } from "react-icons/fa";

import "./tempAudio.css";

export default function TempAudioPlayer(props) {

    const waveformRef = useRef();
    const trackRef = useRef(); // Separated track playing from waveplayer to support bigger audio files
    const [waveSurfer, setWaveSurfer] = useState(null); // Holds the reference to created wavesurfer object

    const [playingAudio, setPlayingAudio] = useState(false);
    const [playBackSpeed, setPlayBackSpeed] = useState(1);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);

    const  formatTime = (seconds) => {
      const minutes = Math.floor(seconds / 60);
      const secondsLeft = Math.floor(seconds % 60);
      return `${minutes.toString().padStart(2, '0')}:${secondsLeft.toString().padStart(2, '0')}`;
    }

    const playBackSpeedOptions = props.playBackSpeedOptions ?? [0.5, 1, 1.2, 1.5, 2];

    const playAudio = () => {
        if (!props.hideWave)
            waveSurfer.play();
        else
            trackRef.current.play();
        setPlayingAudio(true);
    };

    const pauseAudio = () => {
        if (!props.hideWave)
            waveSurfer.pause();
        else
            trackRef.current.pause();
        setPlayingAudio(false);
    };

    const changePlaybackSpeed = () => {
        const newSpeed = playBackSpeed === 4 ? 0 : playBackSpeed + 1;
        setPlayBackSpeed(newSpeed);
        trackRef.current.playbackRate = playBackSpeedOptions[newSpeed];
    };

    const seekAudioFifteenSeconds = ahead => {
        if (ahead)
            trackRef.current.currentTime += 15;
        else
            trackRef.current.currentTime -= 15;
    };

    useEffect(() => {
     
      if (waveformRef.current && trackRef.current && !props.hideWave) {
        const wavesurfer = WaveSurfer.create({
          ...props.waveStyles,
          container: "#waveform",
          responsive: true,
          backend: "MediaElement",
        });
        // Load the waveForm json if provided
        props.waveJson
          ? wavesurfer.load(trackRef.current)
          : wavesurfer.load(trackRef.current, props.waveJson);

        wavesurfer.on("ready", () => {
          setWaveSurfer(wavesurfer);
          // Returns the instance to call methods on
          if (typeof props.getWaveSurferInstance === "function") {
            props?.getWaveSurferInstance(waveSurfer);
          }
          wavesurfer.zoom(props.zoom);
        });

        if (props?.events) {
          Object.entries(props.events).map(([key, value]) => {
            waveSurfer.on(key, value);
          });
        }
      }

      const audioElement = trackRef.current
      const handleTimeUpdate = () => {
        setCurrentTime(audioElement.currentTime);
      };
  
      const handleDurationChange = () => {
        setDuration(audioElement.duration);
      };
  
      audioElement.addEventListener('timeupdate', handleTimeUpdate);
      audioElement.addEventListener('durationchange', handleDurationChange);
  

      return () => {
        waveformRef.current = null;
        audioElement.removeEventListener('timeupdate', handleTimeUpdate);
        audioElement.removeEventListener('durationchange', handleDurationChange);
      }

    }, [props, waveSurfer, trackRef]);

    return (
      <>
        <div
          style={
            props.containerStyles
              ? {
                  display: "flex",
                  flexDirection: "row",
                  ...props.containerStyles,
                }
              : {
                  display: "flex",
                  flexDirection: "row",
                  maxWidth: "85vh",
                  marginLeft: "auto",
                  marginRight: "auto",
                }
          }
        >
          {!props.hideImage && (
            <div>
              {/* <img
                            src={props.imageUrl}
                            alt="Audio logo"
                            style={
                                props.imgStyles ? { ...props.imgStyles } : { maxWidth: "150px" }
                            }
                        /> */}
            </div>
          )}
          <div
            style={{
              flexGrow: 7,
              justifyContent: "space-around",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* require('./../sampleAudio/e-commerce-call.mp3') */}
            <div>
              {!props.hideWave && <div ref={waveformRef} id="waveform" />}
              <audio src={props.audioUrl} ref={trackRef} />
            </div>
            <div
              style={{
                justifyContent: "center",
                display: "flex",
                flexDirection: "row",
                marginLeft: "10px",
                alignItems: 'center'
              }}
            >
              <span className="timmer">
               {formatTime(currentTime)}
              </span>
              <FaUndo
                style={{ margin: "20px", cursor: "pointer" }}
                onClick={() => seekAudioFifteenSeconds(false)}
              />{" "}
              {playingAudio ? (
                <FaPause
                  style={{ margin: "20px", cursor: "pointer" }}
                  onClick={() => (playingAudio ? pauseAudio() : playAudio())}
                />
              ) : (
                <FaPlay
                  style={{ margin: "20px", cursor: "pointer" }}
                  onClick={() => (playingAudio ? pauseAudio() : playAudio())}
                />
              )}
              <span
                style={{ margin: "16px", cursor: "pointer" }}
                onClick={() => changePlaybackSpeed()}
              >
                {playBackSpeedOptions[playBackSpeed]}x
              </span>
              <FaRedo
                style={{ margin: "20px", cursor: "pointer" }}
                onClick={() => seekAudioFifteenSeconds(true)}
              />
              <FaTrash
                style={{ margin: "20px", cursor: "pointer" }}
                onClick={() => props.clear()}
              />
              <span className="timmer">
               {formatTime(duration)}
              </span>
            </div>
          </div>
        </div>
      </>
    );
}