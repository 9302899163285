import React, { useEffect, useState } from "react";

const Feature = () => {
  const [isMobileScreen, setIsMobileScreen] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileScreen(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div style={{}}>
      <div
        style={{
          position: "relative",
          padding: "4px 16px 16px",
          margin: "auto",
          maxWidth: "64rem",
          width: "100%",
        }}
      >
        <div
          style={{
            position: "absolute",
            insetX: 0,
            top: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
            flex: "1",
            flexDirection: "row",
          }}
        >
          <svg
            viewBox="0 0 88 88"
            style={{
              width: "100%",
              maxWidth: "64rem",
              color: "#aee5ff",
            }}
          >
            {/* Your SVG code here */}
          </svg>
        </div>
        <div
          style={{
            position: "relative",
            gridGap: "17px",
            display: "grid",
            gridTemplateColumns: isMobileScreen
              ? "repeat(2, minmax(0, 1fr))"
              : "repeat(3, minmax(0, 1fr))",
            // gridTemplateRows: "repeat(2, minmax(0, 1fr))",
            // gridTemplateAreas: "'1 2' '3 4'",
          }}
        >
          {/* Feature Card 1 */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              overflow: "hidden",
              textAlign: "left",
              transition: "box-shadow 0.2s",
              backgroundColor: "#fff",
              borderRadius: "0.75rem",
              boxShadow: "0 0 0.5rem rgba(0, 0, 0, 0.1)",
              ":hover": {
                boxShadow: "0 0 2rem rgba(0, 123, 255, 0.25)",
              },
            }}
          >
            <div style={{ padding: "1.25rem" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "2.5rem",
                  height: "2.5rem",
                  marginBottom: "1rem",
                  borderRadius: "50%",
                  backgroundColor: "#edf2f7",
                }}
              >
                <img
                  width="30"
                  height="30"
                  src="https://img.icons8.com/ios-glyphs/30/decision--v1.png"
                  alt="decision--v1"
                />
              </div>
              <p style={{ marginBottom: "0.5rem", fontWeight: "bold" }}>
                Custom Question Generation:
              </p>
              <p style={{ fontSize: "0.875rem", color: "#2d3748" }}>
                Enter Job Descriptions, Get AI-Crafted Interview Questions -
                Tailored to Assess Key Skills and Experience Levels.
              </p>
            </div>
            <div
              style={{
                width: "100%",
                height: "1px",
                marginLeft: "auto",
                transition: "width 0.3s",
                origin: "left",
                transformOrigin: "left",
                transform: "scaleX(0)",
                backgroundColor: "#667eea",
                ":hover": {
                  transform: "scaleX(1)",
                },
              }}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              overflow: "hidden",
              textAlign: "left",
              transition: "box-shadow 0.2s",
              backgroundColor: "#fff",
              borderRadius: "0.75rem",
              boxShadow: "0 0 0.5rem rgba(0, 0, 0, 0.1)",
              ":hover": {
                boxShadow: "0 0 2rem rgba(0, 123, 255, 0.25)",
              },
            }}
          >
            <div style={{ padding: "1.25rem" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "2.5rem",
                  height: "2.5rem",
                  marginBottom: "1rem",
                  borderRadius: "50%",
                  backgroundColor: "#edf2f7",
                }}
              >
                <img
                  width="30"
                  height="30"
                  src="https://img.icons8.com/ios-glyphs/30/decision--v1.png"
                  alt="decision--v1"
                />
              </div>
              <p style={{ marginBottom: "0.5rem", fontWeight: "bold" }}>
                Data Security
              </p>
              <p style={{ fontSize: "0.875rem", color: "#2d3748" }}>
                Secure Your Sales Call Data on Your Server for Top-Level Data
                Security.
              </p>
            </div>
            <div
              style={{
                width: "100%",
                height: "1px",
                marginLeft: "auto",
                transition: "width 0.3s",
                origin: "left",
                transformOrigin: "left",
                transform: "scaleX(0)",
                backgroundColor: "#667eea",
                ":hover": {
                  transform: "scaleX(1)",
                },
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              overflow: "hidden",
              textAlign: "left",
              transition: "box-shadow 0.2s",
              backgroundColor: "#fff",
              borderRadius: "0.75rem",
              boxShadow: "0 0 0.5rem rgba(0, 0, 0, 0.1)",
              ":hover": {
                boxShadow: "0 0 2rem rgba(0, 123, 255, 0.25)",
              },
            }}
          >
            <div style={{ padding: "1.25rem" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "2.5rem",
                  height: "2.5rem",
                  marginBottom: "1rem",
                  borderRadius: "50%",
                  backgroundColor: "#edf2f7",
                }}
              >
                <img
                  width="30"
                  height="30"
                  src="https://img.icons8.com/ios-glyphs/30/decision--v1.png"
                  alt="decision--v1"
                />
              </div>
              <p style={{ marginBottom: "0.5rem", fontWeight: "bold" }}>
                Call Transcripts
              </p>
              <p style={{ fontSize: "0.875rem", color: "#2d3748" }}>
                Automatically Generate Detailed Transcripts of Your Sales Calls
                for Easy Reference and Analysis.
              </p>
            </div>
            <div
              style={{
                width: "100%",
                height: "1px",
                marginLeft: "auto",
                transition: "width 0.3s",
                origin: "left",
                transformOrigin: "left",
                transform: "scaleX(0)",
                backgroundColor: "#667eea",
                ":hover": {
                  transform: "scaleX(1)",
                },
              }}
            />
          </div>

          {/* Repeat the above structure for the remaining feature cards */}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap:"17px"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              overflow: "hidden",
              textAlign: "left",
              transition: "box-shadow 0.2s",
              backgroundColor: "#fff",
              borderRadius: "0.75rem",
              boxShadow: "0 0 0.5rem rgba(0, 0, 0, 0.1)",
              ":hover": {
                boxShadow: "0 0 2rem rgba(0, 123, 255, 0.25)",
              },
              width: "25rem",
              marginTop: "17px",
            }}
          >
            <div style={{ padding: "1.25rem" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "2.5rem",
                  height: "2.5rem",
                  marginBottom: "1rem",
                  borderRadius: "50%",
                  backgroundColor: "#edf2f7",
                }}
              >
                <img
                  width="30"
                  height="30"
                  src="https://img.icons8.com/ios-glyphs/30/decision--v1.png"
                  alt="decision--v1"
                />
              </div>
              <p style={{ marginBottom: "0.5rem", fontWeight: "bold" }}>
                Call Overview and Summary
              </p>
              <p style={{ fontSize: "0.875rem", color: "#2d3748" }}>
                Obtain Concise Overviews and Summaries of Your Calls, Saving You
                Time and Providing Quick Insights.
              </p>
            </div>
            <div
              style={{
                width: "100%",
                height: "1px",
                marginLeft: "auto",
                transition: "width 0.3s",
                origin: "left",
                transformOrigin: "left",
                transform: "scaleX(0)",
                backgroundColor: "#667eea",
                ":hover": {
                  transform: "scaleX(1)",
                },
              }}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              overflow: "hidden",
              textAlign: "left",
              transition: "box-shadow 0.2s",
              backgroundColor: "#fff",
              borderRadius: "0.75rem",
              boxShadow: "0 0 0.5rem rgba(0, 0, 0, 0.1)",
              ":hover": {
                boxShadow: "0 0 2rem rgba(0, 123, 255, 0.25)",
              },
              width: "25rem",
              marginTop: "17px",
            }}
          >
            <div style={{ padding: "1.25rem" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "2.5rem",
                  height: "2.5rem",
                  marginBottom: "1rem",
                  borderRadius: "50%",
                  backgroundColor: "#edf2f7",
                }}
              >
                <img
                  width="30"
                  height="30"
                  src="https://img.icons8.com/ios-glyphs/30/decision--v1.png"
                  alt="decision--v1"
                />
              </div>
              <p style={{ marginBottom: "0.5rem", fontWeight: "bold" }}>
                In-Depth Insights
              </p>
              <p style={{ fontSize: "0.875rem", color: "#2d3748" }}>
                Access a Wealth of Insights, Including Sentiment Analysis,
                Speech Clarity, Profanity Checks, and More.
              </p>
            </div>
            <div
              style={{
                width: "100%",
                height: "1px",
                marginLeft: "auto",
                transition: "width 0.3s",
                origin: "left",
                transformOrigin: "left",
                transform: "scaleX(0)",
                backgroundColor: "#667eea",
                ":hover": {
                  transform: "scaleX(1)",
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feature;
