import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ImageIcon from "@mui/icons-material/Image";
import WorkIcon from "@mui/icons-material/Work";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import Divider from "@mui/material/Divider";
import "../../index.css"

export default function InsetDividers({ analyzeData, generateData }) {
  return (
    <div style={{ display: "flex", gap: "6rem", flexWrap: "wrap", justifyContent: '', marginTop: "2rem" }}>
      <div style={{ width: "35rem"}}>
        <span
          style={{
            fontSize: "1.2rem",
            fontWeight: "500",
            color: "#000",
          }}
        >
          Speaker 1
        </span>
        <List
          sx={{
            width: "100%",
            maxWidth: "none",
            marginTop: "1rem",
            bgcolor: "background.paper",
            
          }}
        >
          <ListItem>
            <ListItemAvatar>
              {/* <Avatar> */}
              <img
                width="24"
                height="24"
                src="https://img.icons8.com/external-tanah-basah-basic-outline-tanah-basah/24/000000/external-Profanity-chat-tanah-basah-basic-outline-tanah-basah.png"
                alt="external-Profanity-chat-tanah-basah-basic-outline-tanah-basah"
              />
              {/* </Avatar> */}
            </ListItemAvatar>
            <ListItemText
              primary="Profanity"
              secondary={
                generateData?.data?.data?.["SPEAKER 1"]?.profanity?.score
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem>
            <ListItemAvatar>
              <img
                width="24"
                height="24"
                src="https://img.icons8.com/external-outline-black-m-oki-orlando/32/external-readability-seo-outline-black-m-oki-orlando.png"
                alt="external-readability-seo-outline-black-m-oki-orlando"
              />
            </ListItemAvatar>
            <ListItemText
              primary="Readability
"
              secondary={generateData?.data?.data?.["SPEAKER 1"].readability}
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem>
            <ListItemAvatar>
              <img
                width="24"
                height="24"
                src="https://img.icons8.com/ios/50/fine-print--v1.png"
                alt="fine-print--v1"
              />
            </ListItemAvatar>
            <ListItemText
              primary="Sentiment"
              // secondary={generateData?.data?.data?.["SPEAKER 1"].readability}
            />
          </ListItem>
          <div style={{ marginLeft: "4.2rem" }}>
            <div style={{ display: "flex" }}>
              <p style={{ padding: 0 }}>Compound:</p>
              <ListItemText
                secondary={
                  generateData?.data?.data?.["SPEAKER 1"].sentiment?.compound
                }
              />
            </div>
            <div style={{ display: "flex",gap:"2.7rem" }}>
              <p style={{ padding: 0 }}>Label:</p>
              <ListItemText
                secondary={
                  generateData?.data?.data?.["SPEAKER 1"].sentiment?.label
                }
              />
            </div>
            <div style={{ display: "flex",gap:"1.2rem" }}>
              <p style={{ padding: 0 }}>negative:</p>
              <ListItemText
                secondary={
                  generateData?.data?.data?.["SPEAKER 1"].sentiment?.negative
                }
              />
            </div>
            <div style={{ display: "flex",gap:"1.9rem" }}>
              <p style={{ padding: 0 }}>neutral:</p>
              <ListItemText
                secondary={
                  generateData?.data?.data?.["SPEAKER 1"].sentiment?.neutral
                }
              />
            </div>

            <div style={{ display: "flex", height: "180px", overflow: "auto", flexDirection: "column" }}>
              <div style={{ padding: 0 }}>text:</div>
              <ListItemText style={{marginLeft: "-7px"}}
                secondary={generateData?.data?.data?.["SPEAKER 1"]?.text}
              />
            </div>
          </div>
        </List>
      </div>

      <div style={{ width: "35rem" }}>
        <span
          style={{
            fontSize: "1.2rem",
            fontWeight: "500",
            color: "#000",
           
          }}
        >
          Speaker 2
        </span>
        <List
          sx={{
            width: "100%",
            maxWidth: "none",
            marginTop: "1rem",
            bgcolor: "background.paper",
          }}
        >
          <ListItem>
            <ListItemAvatar>
              <img
                width="24"
                height="24"
                src="https://img.icons8.com/external-tanah-basah-basic-outline-tanah-basah/24/000000/external-Profanity-chat-tanah-basah-basic-outline-tanah-basah.png"
                alt="external-Profanity-chat-tanah-basah-basic-outline-tanah-basah"
              />
            </ListItemAvatar>
            <ListItemText
              primary="Profanity"
              secondary={
                generateData?.data?.data?.["SPEAKER 1"]?.profanity?.score
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem>
            <ListItemAvatar>
              <img
                width="24"
                height="24"
                src="https://img.icons8.com/external-outline-black-m-oki-orlando/32/external-readability-seo-outline-black-m-oki-orlando.png"
                alt="external-readability-seo-outline-black-m-oki-orlando"
              />
            </ListItemAvatar>
            <ListItemText
              primary="Readability
"
              secondary={generateData?.data?.data?.["SPEAKER 1"].readability}
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem>
            <ListItemAvatar>
              <img
                width="24"
                height="24"
                src="https://img.icons8.com/external-outline-black-m-oki-orlando/32/external-readability-seo-outline-black-m-oki-orlando.png"
                alt="external-readability-seo-outline-black-m-oki-orlando"
              />
            </ListItemAvatar>
            <ListItemText
              primary="Sentiment"
              // secondary={generateData?.data?.data?.["SPEAKER 1"].readability}
            />
          </ListItem>
          <div style={{ marginLeft: "4.2rem" }}>
            <div style={{ display: "flex" }}>
              <p style={{ padding: 0 }}>Compound:</p>
              <ListItemText
                secondary={
                  generateData?.data?.data?.["SPEAKER 2"].sentiment?.compound
                }
              />
            </div>
            <div style={{ display: "flex",gap:"2.7rem" }}>
              <p style={{ padding: 0 }}>Label:</p>
              <ListItemText
                secondary={
                  generateData?.data?.data?.["SPEAKER 2"].sentiment?.label
                }
              />
            </div>
            <div style={{ display: "flex",gap:"1.2rem"  }}>
              <p style={{ padding: 0 }}>negative:</p>
              <ListItemText
                secondary={
                  generateData?.data?.data?.["SPEAKER 2"].sentiment?.negative
                }
              />
            </div>
            <div style={{ display: "flex",gap:"1.9rem" }}>
              <p style={{ padding: 0 }}>neutral:</p>
              <ListItemText
                secondary={
                  generateData?.data?.data?.["SPEAKER 2"].sentiment?.neutral
                }
              />
            </div>

            <div style={{ display: "flex", height: "180px", overflow: "auto", flexDirection: "column" }}>
              <p style={{ padding: 0 }}>text:</p>
              <ListItemText
              style={{marginLeft: "-7px"}}
                secondary={generateData?.data?.data?.["SPEAKER 2"]?.text}
              />
            </div>
          </div>
        </List>
      </div>
    </div>
  );
}
