import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import "./styles/fileUploder.css"
import { RiUploadCloud2Fill } from "react-icons/ri";


// const fileTypes = ["JPEG", "PNG", "GIF"];

const UploadAudio = ({setAudioFile,setAudioFiles}) => {
  const [file, setFile] = useState(null);
  console.log("fileasas",file)
  const handleChange = (file) => {
    console.log("dle",file[0]);
    setFile(file);
    setAudioFile(file)
    setAudioFiles(file[0])
  };
  return (
    <div className="uploadOuterContainer">
      <FileUploader multiple={true} handleChange={handleChange} name="file">
        <div className="uploadContainer">
          <RiUploadCloud2Fill className="uploadIcon" />
          <span className="uploadText">Drag and drop or Browse Audio</span>
        </div>
      </FileUploader>
    </div>
  );
}

export default UploadAudio;
