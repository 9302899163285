import React, { useEffect, useState } from "react";
import TempAudioPlayer from "../TempAudioPlayer";
import UploadAudio from "../FileUploader";
import axios from "axios";
import "./audioContainer.scss";

const AudioContainer = (props) => {
  const [audioUrl, setAudioUrl] = useState();
  const [speakers, setSpeakers] = useState("");
  const [audioFiles, setAudioFiles] = useState();
  const [analyzeData, setAnalyzeData] = useState();
  const [generateData, setGenerateData] = useState();

  const [loading, setLoading] = useState(false);

  const { src, speaker, src_api, name } = props.data;
  console.log("audioUrl", props?.data?.name);
  const [binaryData, setBinaryData] = useState(null);
  useEffect(() => {
    if (src) {
      setAudioUrl(null);
      setTimeout(() => {
        setAudioUrl(src);
        setSpeakers(speaker);
      }, 0);
    } else {
      setAudioUrl();
    }
  }, [src, speaker]);

  const handleSetUploadAudioFile = (file) => {
    setAudioUrl(URL.createObjectURL(file[0]));
    props.clearData();
  };

  const handleClearAudio = () => {
    setAudioUrl(null);
  };

  const handleAnalysisSubmit = async () => {
    console.log("caling");
    try {
      let con = false;
      if (!con) {
        fetch(src_api)
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.arrayBuffer();
          })
          .then(async (arrayBuffer) => {
            try {
              const formData = new FormData();
              formData.append("audio", new Blob([arrayBuffer]));
              formData.append("name", props.data.name ?? "example_1");
              formData.append("num_speakers", speakers || 2);

              const analyzeAudioResponse = await axios.post(
                "https://coops-backend.bluetickconsultants.com:8010/analyze_audio",
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              );

              setAnalyzeData(analyzeAudioResponse.data.data);
              localStorage.setItem(
                "analyzeData",
                JSON.stringify(analyzeAudioResponse.data.data)
              );
              console.log("datamain", analyzeAudioResponse.data.data);
              const analysis_id = analyzeAudioResponse.data.data;

              const generateAnalysisResponse = await axios.post(
                "https://coops-backend.bluetickconsultants.com:8010/generate_analysis",
                {
                  raw_data: analysis_id?.raw_data,
                  raw_text: analysis_id?.raw_text,
                  // raw_data: {
                  //   "SPEAKER 1": {
                  //     text: "Hello, I'd like to order flowers and I think you have what I'm looking for.Randall, R-A-N-D-A-L-L, Thomas, T-H-O-M-A-N.Area code is 409-866-5088.My email is randall.thomas at gmail.com.6800 Ladders Avenue,Beaumont, Texas. Zip code is 77706.Red roses. Probably a dozen.Yes, sure.Okay.How soon can you deliver my roses again?Okay, no problem.That's all for now. Thanks.Martha's Florist",
                  //   },
                  //   "SPEAKER 2": {
                  //     text: "Thank you for calling Martha's Flowers. How may I assist you?I'd be happy to take care of your order. May I have your name, please?Randall Thomas.Randall Thomas. Can you spell that for me?Thank you for that information, Randall. May I have your home or office number and area code first?That's 409-866-5088. Do you have a fax number or email address?randall.thomas at gmail.com. May I have your shipping address?Ladders Avenue, Beaumont, Texas. Zip code is 77706.Thank you for the information. What products are you interested in purchasing?One dozen of red roses? Do you want long stems?Alright. Randall, let me process your order. One moment, please.Randall, you are ordering one dozen long stemmed red roses. The total amount of your order is $40.And it will be shipped to your address within 24 hours.Within 24 hours.Is there anything else I can help you with?No problem, Randall. Thank you for calling Martha's Florist. Have a nice day.",
                  //   },
                  // },
                  // raw_text:
                  //   "Thank you for calling Martha's Flowers. How may I assist you? Hello, I'd like to order flowers and I think you have what I'm looking for. I'd be happy to take care of your order. May I have your name, please? Randall Thomas. Randall Thomas. Can you spell that for me? Randall, R-A-N-D-A-L-L, Thomas, T-H-O-M-A-N. Thank you for that information, Randall. May I have your home or office number and area code first? Area code is 409-866-5088. That's 409-866-5088. Do you have a fax number or email address? My email is randall.thomas at gmail.com. randall.thomas at gmail.com. May I have your shipping address? 6800 Ladders Avenue, Beaumont, Texas. Zip code is 77706. Ladders Avenue, Beaumont, Texas. Zip code is 77706. Thank you for the information. What products are you interested in purchasing? Red roses. Probably a dozen. One dozen of red roses? Do you want long stems? Yes, sure. Alright. Randall, let me process your order. One moment, please. Okay. Randall, you are ordering one dozen long stemmed red roses. The total amount of your order is $40. And it will be shipped to your address within 24 hours. How soon can you deliver my roses again? Within 24 hours. Okay, no problem. Is there anything else I can help you with? That's all for now. Thanks. No problem, Randall. Thank you for calling Martha's Florist. Have a nice day. Martha's Florist",
                  name: props?.data?.name || "example_1",
                  num_speakers: props?.data?.speaker || 2,
                }
              );

              setGenerateData(generateAnalysisResponse.data);
              localStorage.setItem(
                "generateData",
                JSON.stringify(generateAnalysisResponse.data)
              );
              window.location.href = "#/app-submit";
              console.log("Analysis Result:", generateAnalysisResponse.data);
            } catch (error) {
              console.error("API Error:", error);
            }
          })
          .catch((error) => {
            console.error("Error loading media file:", error);
          });
      } else {
        const formData = new FormData();

        formData.append("audio", audioFiles);

        // Assuming props.data.name is available
        formData.append("name", props.data.name ?? "example_1");

        // Add any additional form data
        formData.append("num_speakers", speakers || 2);

        // API call to analyze_audio
        const analyzeAudioResponse = await axios.post(
          "https://coops-backend.bluetickconsultants.com:8010/analyze_audio",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        // Extract the necessary data from the response
        const { analysis_id } = analyzeAudioResponse.data;

        // API call to generate_analysis
        const generateAnalysisResponse = await axios.post(
          "https://coops-backend.bluetickconsultants.com:8010/generate_analysis",
          {
            analysis_id,
            // Add any other necessary data here
          }
        );

        // Handle the response as needed
        console.log("Analysis Result:", generateAnalysisResponse.data);
      }
    } catch (error) {
      console.error("API Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="audioContainer">
      {audioUrl && (
        <>
          {loading && (
            <div className="loader">
              {/* Add your loading animation or text here */}
              Loading...
            </div>
          )}
          <div className="audioPlayerContainer">
            <TempAudioPlayer
              audioUrl={audioUrl}
              clear={handleClearAudio}
              waveStyles={{
                cursorWidth: 1,
                progressColor: "orange",
                responsive: true,
                waveColor: "#121640",
                cursorColor: "transparent",
                barWidth: 2,
              }}
              zoom={0}
            />
          </div>

          <div className="audioContainer-numberContainer">
            <span>Select number of speakers</span>
            <input
              type="number"
              value={speakers}
              onChange={(e) => setSpeakers(e.target.value)}
              className="audioContainer-number"
            />
            <div className="audioContainer-buttonContainer">
              <span
                className="audioContainer-buttonContainer-button"
                onClick={handleAnalysisSubmit}
              >
                Submit
              </span>
              <span
                className="audioContainer-buttonContainer-button audioContainer-buttonContainer-button-clear"
                onClick={handleClearAudio}
              >
                Clear
              </span>
            </div>
          </div>
        </>
      )}
      {!audioUrl && (
        <UploadAudio
          setAudioFile={handleSetUploadAudioFile}
          setAudioFiles={setAudioFiles}
        />
      )}
    </div>
  );
};

export default AudioContainer;
